:root {
  --primary-color: #543125;
  --secondary-color: #4f762f;
  --black-color: #000000;
  --white-color: #ffffff;
  --info-color: #C4C4C4;
  --muted-color: #E5E5E5;
}

@font-face {
  font-family: 'PyidaungsuRegular';
  src: url('fonts/PyidaungsuRegular.ttf') format('truetype');
  /* Add more font formats if necessary */
}

body {
  margin: 0;
  font-family: 'PyidaungsuRegular', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #000 !important;
  text-decoration: none;
}

/* label style override */
.ant-form-item-required {
  color: var(--white-color) !important;
  background-color: var(--primary-color);
  padding: 4px !important;
  margin-top: 4px !important;
  margin-right: 4px !important;
  border-radius: 10px;
}

/* menu style override */
.ant-tooltip-inner a{
  color: var(--white-color) !important;
}

/* Table Header Bg Color */
.ant-table-thead tr th {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
  }
}